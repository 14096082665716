import { Injectable } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { CepService } from './cep.service'
import { DataService } from './data.service'

@Injectable({
  providedIn: 'root',
})
export class ValidacaoService {
  constructor(private cepService: CepService, private dataService: DataService) {}

  validaCEP(form: FormGroup, endNacional: boolean): FormGroup {
    const cepCompleto = form.get('enderecoCep')?.value
    const enderecoNacional = endNacional
    if (cepCompleto) {
      if (enderecoNacional === true) {
        const cep = cepCompleto.replace('.', '').replace('-', '')
        form.get('enderecoLogradouro')?.disable()
        form.get('enderecoBairro')?.disable()
        form.get('enderecoCidade')?.disable()
        form.get('enderecoEstado')?.disable()
        this.cepService.consultaCEP(cep).subscribe(endereco => {
          if (endereco.erro) {
            form.get('enderecoCep')?.setErrors({ invalid: true })
            form.get('enderecoLogradouro')?.setValue('')
            form.get('enderecoLogradouro')?.disable()
            form.get('enderecoBairro')?.setValue('')
            form.get('enderecoBairro')?.disable()
            form.get('enderecoCidade')?.setValue('')
            form.get('enderecoCidade')?.disable()
            form.get('enderecoEstado')?.setValue('')
            form.get('enderecoEstado')?.disable()
          } else {
            if (endereco.logradouro) {
              form.get('enderecoLogradouro')?.setValue(endereco.logradouro)
              form.get('enderecoLogradouro')?.disable()
            } else {
              form.get('enderecoLogradouro')?.enable()
            }
            if (endereco.bairro) {
              form.get('enderecoBairro')?.setValue(endereco.bairro)
              form.get('enderecoBairro')?.disable()
            } else {
              form.get('enderecoBairro')?.enable()
            }
            form.get('enderecoCidade')?.setValue(endereco.localidade)
            form.get('enderecoCidade')?.disable()
            form.get('enderecoEstado')?.setValue(endereco.uf)
            form.get('enderecoEstado')?.disable()
          }
        })
      } else {
        form.get('enderecoLogradouro')?.enable()
        form.get('enderecoBairro')?.enable()
        form.get('enderecoCidade')?.enable()
        form.get('enderecoEstado')?.enable()
      }
    } else {
      form.get('enderecoLogradouro')?.enable()
      form.get('enderecoBairro')?.enable()
      form.get('enderecoCidade')?.enable()
      form.get('enderecoEstado')?.enable()
    }
    return form
  }

  validaNomeSocial(form: FormGroup, ativo: boolean): FormGroup {
    if (ativo) {
      form.get('nomeSocial')?.enable()
      form.get('nomeSocial')?.setValidators(Validators.required)
      form.get('nomeSocial')?.updateValueAndValidity()
    } else {
      form.get('nomeSocial')?.disable()
      form.get('nomeSocial')?.reset()
      form.get('nomeSocial')?.clearValidators()
      form.get('nomeSocial')?.updateValueAndValidity()
    }
    return form
  }

  validaMaeDesconhecida(form: FormGroup, ativo: boolean): FormGroup {
    if (ativo) {
      form.get('nomeMae')?.enable()
      form.get('nomeMae')?.setValidators(Validators.required)
      form.get('nomeMae')?.updateValueAndValidity()
    } else {
      form.get('nomeMae')?.disable()
      form.get('nomeMae')?.reset()
      form.get('nomeMae')?.clearValidators()
      form.get('nomeMae')?.updateValueAndValidity()
    }
    return form
  }

  validaPaiDesconhecido(form: FormGroup, ativo: boolean): FormGroup {
    if (ativo) {
      form.get('nomePai')?.enable()
      form.get('nomePai')?.setValidators(Validators.required)
      form.get('nomePai')?.updateValueAndValidity()
    } else {
      form.get('nomePai')?.disable()
      form.get('nomePai')?.reset()
      form.get('nomePai')?.clearValidators()
      form.get('nomePai')?.updateValueAndValidity()
    }
    return form
  }

  validaEstrangeiro(form: FormGroup): FormGroup {
    const rgPattern = '(^[0-9]{6})([0-9a-zA-Z]{0,7})'
    const cpfPattern = '(^\\d{3}\\.\\d{3}\\.\\d{3}-\\d{2}$)'
    const datePattern = '(^\\d{2}\\/\\d{2}\\/\\d{4}$)'
    const selecao = form.get('estrangeiro')?.value
    form.get('pais')?.enable()
    form.get('estado')?.enable()
    form.get('cidade')?.enable()
    if (selecao === 'NAO' || selecao === 'NATURALIZADO') {
      form.get('cpf')?.enable()
      form.get('cpf')?.setValidators([Validators.required, Validators.pattern(cpfPattern)])
      form.get('cpf')?.updateValueAndValidity()
      form.get('passaporte')?.disable()
      form.get('passaporte')?.reset()
      form.get('passaporte')?.clearValidators()
      form.get('passaporte')?.updateValueAndValidity()
      form.get('rne')?.disable()
      form.get('rne')?.reset()
      form.get('rne')?.clearValidators()
      form.get('rne')?.updateValueAndValidity()
      form.get('rg')?.enable()
      form.get('rg')?.setValidators([Validators.required, Validators.pattern(rgPattern)])
      form.get('rg')?.updateValueAndValidity()
      form.get('uf')?.enable()
      form.get('uf')?.setValidators(Validators.required)
      form.get('uf')?.updateValueAndValidity()
      form.get('dataExpedicao')?.enable()
      form.get('dataExpedicao')?.setValidators([Validators.required, Validators.pattern(datePattern)])
      form.get('dataExpedicao')?.updateValueAndValidity()
      form.get('orgaoExpedicao')?.enable()
      form.get('orgaoExpedicao')?.setValidators(Validators.required)
      form.get('orgaoExpedicao')?.updateValueAndValidity()
    } else if (selecao === 'SIM') {
      form.get('cpf')?.enable()
      form.get('cpf')?.clearValidators()
      form.get('cpf')?.updateValueAndValidity()
      form.get('passaporte')?.enable()
      form.get('passaporte')?.setValidators(Validators.required)
      form.get('passaporte')?.updateValueAndValidity()
      form.get('rne')?.enable()
      //form.get('rne')?.setValidators(Validators.required)
      form.get('rne')?.updateValueAndValidity()
      form.get('rg')?.disable()
      form.get('rg')?.reset()
      form.get('rg')?.clearValidators()
      form.get('rg')?.updateValueAndValidity()
      form.get('uf')?.disable()
      form.get('uf')?.reset()
      form.get('uf')?.clearValidators()
      form.get('uf')?.updateValueAndValidity()
      form.get('dataExpedicao')?.disable()
      form.get('dataExpedicao')?.reset()
      form.get('dataExpedicao')?.clearValidators()
      form.get('dataExpedicao')?.updateValueAndValidity()
      form.get('orgaoExpedicao')?.disable()
      form.get('orgaoExpedicao')?.reset()
      form.get('orgaoExpedicao')?.clearValidators()
      form.get('orgaoExpedicao')?.updateValueAndValidity()
    }
    return form
  }

  validaDeficiencia(form: FormGroup): FormGroup {
    const possuiDeficiencia = form.get('possuiDeficiencia')?.value
    if (possuiDeficiencia === 'true') {
      form.get('deficiencia')?.enable()
      form.get('deficiencia')?.setValidators(Validators.required)
      form.get('deficiencia')?.updateValueAndValidity()
      form.get('deficienciaLaudoMedico')?.enable()
      form.get('deficienciaLaudoMedico')?.setValidators(Validators.required)
      form.get('deficienciaLaudoMedico')?.updateValueAndValidity()
      form.get('deficienciaNecessidadesProva')?.enable()
      form.get('deficienciaNecessidadesProva')?.setValidators(Validators.required)
      form.get('deficienciaNecessidadesProva')?.updateValueAndValidity()
    } else {
      form.get('deficiencia')?.disable()
      form.get('deficiencia')?.reset()
      form.get('deficiencia')?.clearValidators()
      form.get('deficiencia')?.updateValueAndValidity()
      form.get('deficienciaLaudoMedico')?.disable()
      form.get('deficienciaLaudoMedico')?.reset()
      form.get('deficienciaLaudoMedico')?.clearValidators()
      form.get('deficienciaLaudoMedico')?.updateValueAndValidity()
      form.get('deficienciaNecessidadesProva')?.disable()
      form.get('deficienciaNecessidadesProva')?.reset()
      form.get('deficienciaNecessidadesProva')?.clearValidators()
      form.get('deficienciaNecessidadesProva')?.updateValueAndValidity()
    }
    return form
  }

  desabilitaCamposRaciais(form: FormGroup) {
    form.get('autodeclaracao')?.disable()
    form.get('autodeclaracao')?.reset()
    form.get('autodeclaracao')?.clearValidators()
    form.get('autodeclaracao')?.setValue('NAO_INFORMADO')

    form.get('pontuacaoDiferenciada')?.updateValueAndValidity()
    form.get('pontuacaoDiferenciada')?.disable()
    form.get('pontuacaoDiferenciada')?.reset()
    form.get('pontuacaoDiferenciada')?.setValue('false')

    form.get('declaracaoVeracidade')?.clearValidators()
    form.get('declaracaoVeracidade')?.updateValueAndValidity()
    form.get('declaracaoVeracidade')?.disable()
  }

  habilitaCamposRaciais(form: FormGroup) {
    form.get('autodeclaracao')?.enable()
    form.get('autodeclaracao')?.setValue('NAO_INFORMADO')
    form.get('autodeclaracao')?.setValidators(Validators.required)
    form.get('autodeclaracao')?.updateValueAndValidity()

    form.get('pontuacaoDiferenciada')?.enable()
    form.get('pontuacaoDiferenciada')?.setValidators(Validators.required)
    form.get('pontuacaoDiferenciada')?.updateValueAndValidity()

    form.get('declaracaoVeracidade')?.enable()
    form.get('declaracaoVeracidade')?.setValidators(Validators.requiredTrue)
    form.get('declaracaoVeracidade')?.updateValueAndValidity()
  }

  validaAutodeclaracao(form: FormGroup, tipoConcurso: string | undefined): FormGroup {
    const autodeclaraRaca = form.get('autodeclaraRaca')?.value
    const autodeclaracao = form.get('autodeclaracao')?.value
    const pontuacao = form.get('pontuacaoDiferenciada')?.value
    const declaraVeracidade = form.get('declaracaoVeracidade')?.value

    if (tipoConcurso?.localeCompare('TECADM') === 0) {
      if (autodeclaraRaca === null || autodeclaraRaca === '' || autodeclaraRaca === undefined) {
        form.get('autodeclaraRaca')?.enable()
        form.get('autodeclaraRaca')?.setErrors({ incorrect: true })
        return form
      } else {
        if (autodeclaraRaca === true || autodeclaraRaca === 'true' || autodeclaraRaca.localeCompare('true') === 0) {
          if (
            autodeclaracao === null ||
            autodeclaracao === 'NAO_INFORMADO' ||
            autodeclaracao === '' ||
            autodeclaracao.localeCompare('NAO_INFORMADO') === 0 ||
            autodeclaracao.localeCompare('') === 0
          ) {
            form.get('autodeclaracao')?.setErrors({ incorrect: true })
            return form
          }

          if (
            pontuacao === null ||
            pontuacao === '' ||
            pontuacao === 'NAO_INFORMADO' ||
            pontuacao.localeCompare('') === 0 ||
            pontuacao.localeCompare('NAO_INFORMADO') === 0
          ) {
            form.get('pontuacaoDiferenciada')?.setErrors({ incorrect: true })
          }

          if (declaraVeracidade !== true) {
            form.get('declaracaoVeracidade')?.setErrors({ incorrect: true })
          }
        } else {
          this.desconsideraCamposRaciais(form)
        }
      }
    } else {
      this.desconsideraCamposRaciais(form)
    }
    return form
  }

  desconsideraCamposRaciais(form: FormGroup) {
    form.get('autodeclaraRaca')?.setValue('false')
    form.get('autodeclaraRaca')?.disable()
    form.get('autodeclaraRaca')?.reset()
    form.get('autodeclaraRaca')?.clearValidators()

    form.get('autodeclaracao')?.disable()
    form.get('autodeclaracao')?.reset()
    form.get('autodeclaracao')?.clearValidators()
    form.get('autodeclaracao')?.setValue('NAO_INFORMADO')

    form.get('pontuacaoDiferenciada')?.updateValueAndValidity()
    form.get('pontuacaoDiferenciada')?.disable()
    form.get('pontuacaoDiferenciada')?.reset()
    form.get('pontuacaoDiferenciada')?.setValue(false)

    form.get('declaracaoVeracidade')?.clearValidators()
    form.get('declaracaoVeracidade')?.updateValueAndValidity()
    form.get('declaracaoVeracidade')?.disable()
    form.get('declaracaoVeracidade')?.setValue(false)
  }

  validaAutodeclaracaoEPontuacao(form: FormGroup, tipoConcurso: string | undefined): FormGroup {
    const autodeclaracao = form.get('autodeclaracao')?.value
    const pontuacao = form.get('pontuacaoDiferenciada')?.value
    if (tipoConcurso?.localeCompare('TECADM') === 0) {
      if (autodeclaracao === null || autodeclaracao === 'NAO_INFORMADO' || autodeclaracao === '') {
        form.get('autodeclaracao')?.setErrors({ incorrect: true })
      }

      if (pontuacao === null || pontuacao === '' || pontuacao === 'NAO_INFORMADO') {
        form.get('pontuacaoDiferenciada')?.setErrors({ incorrect: true })
      }
    }
    return form
  }

  validaReducao(form: FormGroup): FormGroup {
    const reducao = form.get('reducao')?.value
    if (reducao === 'true') {
      form.get('reducaoTermo')?.enable()
      form.get('reducaoTermo')?.setValidators(Validators.required)
      form.get('reducaoTermo')?.updateValueAndValidity()
      form.get('reducaoComprovanteEstudante')?.enable()
      form.get('reducaoComprovanteEstudante')?.setValidators(Validators.required)
      form.get('reducaoComprovanteEstudante')?.updateValueAndValidity()
      form.get('reducaoComprovanteRenda')?.enable()
      form.get('reducaoComprovanteRenda')?.setValidators(Validators.required)
      form.get('reducaoComprovanteRenda')?.updateValueAndValidity()
    } else {
      form.get('reducaoTermo')?.disable()
      form.get('reducaoTermo')?.reset()
      form.get('reducaoTermo')?.clearValidators()
      form.get('reducaoTermo')?.updateValueAndValidity()
      form.get('reducaoComprovanteEstudante')?.disable()
      form.get('reducaoComprovanteEstudante')?.reset()
      form.get('reducaoComprovanteEstudante')?.clearValidators()
      form.get('reducaoComprovanteEstudante')?.updateValueAndValidity()
      form.get('reducaoComprovanteRenda')?.disable()
      form.get('reducaoComprovanteRenda')?.reset()
      form.get('reducaoComprovanteRenda')?.clearValidators()
      form.get('reducaoComprovanteRenda')?.updateValueAndValidity()
    }
    return form
  }

  validaTemas(form: FormGroup, hasPontos: boolean): FormGroup {
    if (hasPontos) {
      form.get('temas')?.enable()
      form.get('temas')?.setValidators(Validators.required)
      form.get('temas')?.updateValueAndValidity()
    } else {
      form.get('temas')?.disable()
      form.get('temas')?.reset()
      form.get('temas')?.clearValidators()
      form.get('temas')?.updateValueAndValidity()
    }
    return form
  }

  validaDataNascimento(form: FormGroup): FormGroup {
    const dataNascimento = form.get('dataNascimento')?.value
    if (dataNascimento !== '') {
      if (!this.dataService.dateIsValid(dataNascimento)) {
        form.get('dataNascimento')?.setErrors({ incorrect: true })
      }
    }
    return form
  }

  validaDataExpedicao(form: FormGroup): FormGroup {
    const dataExpedicao = form.get('dataExpedicao')?.value
    if (dataExpedicao !== '') {
      if (!this.dataService.dateIsValid(dataExpedicao)) {
        form.get('dataExpedicao')?.setErrors({ incorrect: true })
      }
    }
    return form
  }

  validaCPF(form: FormGroup): FormGroup {
    const cpf = form.get('cpf')?.value
    if (!this.isCPFValid(cpf)) {
      form.get('cpf')?.setErrors({ incorrect: true })
    }
    return form
  }

  isCPFValid(value: string): boolean {
    const cpf = value.replace(/[^\d]+/g, '')
    if (cpf.length == 0) return true
    if (
      cpf.length != 11 ||
      cpf == '00000000000' ||
      cpf == '11111111111' ||
      cpf == '22222222222' ||
      cpf == '33333333333' ||
      cpf == '44444444444' ||
      cpf == '55555555555' ||
      cpf == '66666666666' ||
      cpf == '77777777777' ||
      cpf == '88888888888' ||
      cpf == '99999999999'
    )
      return false
    // Valida 1o digito
    let add = 0
    for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i)
    let rev = 11 - (add % 11)
    if (rev == 10 || rev == 11) rev = 0
    if (rev != parseInt(cpf.charAt(9))) return false
    // Valida 2o digito
    add = 0
    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i)
    rev = 11 - (add % 11)
    if (rev == 10 || rev == 11) rev = 0
    if (rev != parseInt(cpf.charAt(10))) return false
    return true
  }
}
