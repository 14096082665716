import { Component, OnInit } from '@angular/core'
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker'
import { filter } from 'rxjs'
import packageJson from '../../package.json'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title: string = 'Sistema de inscrições'
  version: string = packageJson.version
  env = environment

  constructor(private swUpdate: SwUpdate) {}

  ngOnInit(): void {
    // datadogRum.init({
    //   applicationId: 'c112082f-5a2b-4a13-814b-463c3ee57b85',
    //   clientToken: 'pube0726a5498995dfd18293af99b7beaf0',
    //   site: 'datadoghq.com',
    //   service:'sinsc-dev',
    //   env:'dev',
    //   sampleRate: 100,
    //   sessionReplaySampleRate: 20,
    //   trackInteractions: true,
    //   trackResources: true,
    //   trackLongTasks: true,
    //   defaultPrivacyLevel:'mask-user-input'
    // })
    // datadogRum.startSessionReplayRecording()

    /* if (this.env.production) {
      datadogRum.init({
        applicationId: '88090599-03bf-4cb1-aee4-12ae5f81d46d',
        clientToken: 'pub220e30ebd0d7b8a51d4f1444096efac2',
        service: 'sinsc',
        site: 'datadoghq.com',
        env: this.env.tag,
        version: this.version,
        allowedTracingUrls: ['https://app.unesp.br', 'https://sistemas.unesp.br', 'https://inscricoes.unesp.br/'],
        sessionSampleRate: 100,
        sessionReplaySampleRate: 5,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      })
    } */

    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
        .subscribe(evt => {
          confirm('Nova versão do sistema disponível. Deseja atualizar?')
          document.location.reload()
        })
    }
  }
}
