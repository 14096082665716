<section *ngIf="!loading">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/concurso/inscricao-aberta" title="concursos" class="link">Concursos</a>
        </li>
        <li class="breadcrumb-item active">Formulário de inscrição</li>
      </ol>
    </nav>
    <div class="alert alert-success">
      <h4>Formulário de inscrição</h4>
      Prezado candidato, preencha todos os campos para prosseguir com a inscrição.
    </div>
    <div class="alert alert-warning">
      <h4>Atenção</h4>
      O envio dos anexos deverá ser feita na Área do candidato dentro do período preestabelecido no Edital.
    </div>
    <form [formGroup]="form">
      <fieldset class="border rounded-3 p-2">
        <legend class="w-auto">Concurso</legend>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <input readonly="readonly" class="form-control" value="{{ concursoAndamento?.campus }}" type="text" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <input readonly="readonly" class="form-control" value="{{ concursoAndamento?.titulo }}" type="text" />
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="border rounded-3 p-2">
        <legend class="w-auto">Dados do candidato</legend>
        <div class="row">
          <div class="col-sm-8" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Nome</span>
              <input (blur)="save('nome')" class="form-control" formControlName="nome" type="text" maxlength="80" />
            </div>
          </div>
          <div class="col-sm-4" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Data de Nascimento</span>
              <input
                (blur)="save('dataNascimento')"
                placeholder="dd/mm/aaaa"
                (change)="validaDataNascimento()"
                class="form-control datepicker"
                mask="00/00/0000"
                [dropSpecialCharacters]="false"
                formControlName="dataNascimento"
                type="text"
                maxlength="10" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Nome Social</span>
              <input
                (blur)="save('nomeSocial')"
                class="form-control"
                formControlName="nomeSocial"
                type="text"
                maxlength="80" />
              <span class="input-group-text">
                <input
                  id="nSocial"
                  (change)="alteraNomeSocial()"
                  title="Utilizo nome social"
                  type="checkbox"
                  class="form-check-input"
                  [checked]="nomeSocial" />
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Nome da Mãe</span>
              <input
                (blur)="save('nomeMae')"
                class="form-control"
                formControlName="nomeMae"
                type="text"
                maxlength="80" />
              <span class="input-group-text">
                <input
                  (change)="alteraMaeDesconhecida()"
                  id="maeDesc"
                  title="Desconhecida"
                  type="checkbox"
                  class="form-check-input"
                  [checked]="nomeMae" />
              </span>
            </div>
          </div>
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Nome do Pai</span>
              <input
                (blur)="save('nomePai')"
                class="form-control"
                formControlName="nomePai"
                type="text"
                maxlength="80" />
              <span class="input-group-text">
                <input
                  (change)="alteraPaiDesconhecido()"
                  id="paiDesc"
                  title="Desconhecido"
                  type="checkbox"
                  class="form-check-input"
                  [checked]="nomePai" />
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Profissão</span>
              <input
                (blur)="save('profissao')"
                class="form-control"
                formControlName="profissao"
                type="text"
                maxlength="120" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-5" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Grau de Escolaridade</span>
              <select class="form-control" (blur)="save('escolaridade')" formControlName="escolaridade">
                <option value=""></option>
                <option *ngFor="let type of grauEscolaridade | keyvalue" [value]="type.key">
                  {{ type.value }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Sexo</span>
              <select class="form-control" (blur)="save('sexo')" formControlName="sexo">
                <option value=""></option>
                <option *ngFor="let type of sexo | keyvalue" [value]="type.key">
                  {{ type.value }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-4" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Estado Civil</span>
              <select class="form-control" (blur)="save('estadoCivil')" formControlName="estadoCivil">
                <option value=""></option>
                <option *ngFor="let type of estadoCivil | keyvalue" [value]="type.key">
                  {{ type.value }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Estrangeiro</span>
              <select
                class="form-control"
                (blur)="save('estrangeiro')"
                (change)="validaEstrangeiro()"
                formControlName="estrangeiro">
                <option value=""></option>
                <option *ngFor="let type of estrangeiro | keyvalue" [value]="type.key">
                  {{ type.value }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">CPF</span>
              <input
                (blur)="save('cpf')"
                (change)="validaCPF()"
                class="form-control"
                mask="000.000.000-00"
                [dropSpecialCharacters]="false"
                formControlName="cpf"
                type="text"
                maxlength="14" />
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Passaporte</span>
              <input
                (blur)="save('passaporte')"
                class="form-control passaporte"
                formControlName="passaporte"
                type="text" />
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">RNE</span>
              <input
                (blur)="save('rne')"
                class="form-control"
                mask="AAAAAAA-A"
                [dropSpecialCharacters]="false"
                formControlName="rne"
                type="text"
                maxlength="9" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">RG</span>
              <input
                (blur)="save('rg')"
                mask="A{13}"
                class="form-control"
                formControlName="rg"
                type="text"
                maxlength="13" />
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">UF</span>
              <select class="form-control" (blur)="save('uf')" formControlName="uf">
                <option value=""></option>
                <option *ngFor="let type of estado | keyvalue" [value]="type.key">
                  {{ type.value }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Data de Expedição</span>
              <input
                (blur)="save('dataExpedicao')"
                placeholder="dd/mm/aaaa"
                (change)="validaDataExpedicao()"
                class="datepicker form-control"
                mask="00/00/0000"
                [dropSpecialCharacters]="false"
                formControlName="dataExpedicao"
                type="text"
                maxlength="10" />
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Órgão</span>
              <select class="form-control" (blur)="save('orgaoExpedicao')" formControlName="orgaoExpedicao">
                <option value=""></option>
                <option *ngFor="let type of orgaoExpedicaoRg | keyvalue" [value]="type.key">
                  {{ type.value }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="border rounded-3 p-2">
        <legend class="w-auto">Local de Nascimento</legend>
        <div id="localNascimento">
          <div class="row">
            <div class="col-sm-4" style="margin-top: 0.5%">
              <div class="input-group">
                <span class="input-group-text">País</span>
                <select
                  class="form-control"
                  (blur)="save('pais')"
                  formControlName="pais"
                  *ngIf="form.controls['estrangeiro'].value === 'NAO'">
                  <option value=""></option>
                  <option value="Brasil">Brasil</option>
                </select>
                <input
                  (blur)="save('pais')"
                  class="form-control"
                  formControlName="pais"
                  type="text"
                  maxlength="80"
                  *ngIf="form.controls['estrangeiro'].value !== 'NAO'" />
              </div>
            </div>
            <div class="col-sm-4" style="margin-top: 0.5%">
              <div class="input-group">
                <span class="input-group-text">Estado</span>
                <select
                  class="form-control"
                  (blur)="save('estado')"
                  (change)="selecionaEstado()"
                  formControlName="estado"
                  *ngIf="form.controls['estrangeiro'].value === 'NAO'">
                  <option value=""></option>
                  <option *ngFor="let type of estado | keyvalue" [value]="type.key">
                    {{ type.value }}
                  </option>
                </select>
                <input
                  (blur)="save('estado')"
                  class="form-control"
                  id="estado"
                  formControlName="estado"
                  type="text"
                  maxlength="80"
                  *ngIf="form.controls['estrangeiro'].value !== 'NAO'" />
              </div>
            </div>
            <div class="col-sm-4" style="margin-top: 0.5%">
              <div class="input-group">
                <span class="input-group-text">Cidade</span>
                <select
                  class="form-control"
                  (blur)="save('cidade')"
                  formControlName="cidade"
                  *ngIf="form.controls['estrangeiro'].value === 'NAO'">
                  <option value=""></option>
                  <option
                    *ngFor="let cidade of cidades"
                    [attr.selected]="cidade === form.controls['cidade'].value ? true : null">
                    {{ cidade }}
                  </option>
                </select>
                <input
                  (blur)="save('cidade')"
                  class="form-control"
                  formControlName="cidade"
                  type="text"
                  maxlength="80"
                  *ngIf="form.controls['estrangeiro'].value !== 'NAO'" />
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="border rounded-3 p-2">
        <legend class="w-auto">Informações de Contato</legend>
        <div class="row">
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Celular</span>
              <input
                (blur)="validatePhoneNumber()"
                (keypress)="restrictPhoneInput($event)"
                class="form-control celular"
                formControlName="celular"
                type="text"
                minlength="7"
                maxlength="20"
                placeholder="+351 925 111 888 / +55 11 95235 8532" />
            </div>
          </div>
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Telefone</span>
              <input
                (blur)="validatePhoneNumber()"
                (keypress)="restrictPhoneInput($event)"
                class="form-control telefone"
                formControlName="telefone"
                type="text"
                maxlength="20"
                placeholder="+351 925 111 888 / +55 11 5235 8532" />
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="border rounded-3 p-2">
        <span class="input-group-text">Endereço no Brasil?</span>
        <select
          class="form-control"
          style="margin-top: 0.5%"
          (change)="alteraTipoEndereco()"
          formControlName="enderecoNacional"
          required>
          <option value="" selected>Selecione</option>
          <option value="true">Sim</option>
          <option value="false">Não</option>
        </select>

        <legend class="w-auto">Endereço</legend>
        <div class="row">
          <div class="col-sm-4" style="margin-top: 0.5%">
            <ng-container
              *ngIf="
                form.controls.enderecoNacional.value === 'true';
                then contextoNacional;
                else contextoInternacional
              ">
            </ng-container>
            <ng-template #contextoNacional>
              <div class="input-group">
                <span class="input-group-text">CEP</span>
                <input
                  class="form-control"
                  (blur)="save('enderecoCep'); validaCEP(enderecoNacional)"
                  mask="00.000-000"
                  [dropSpecialCharacters]="false"
                  formControlName="enderecoCep"
                  type="text"
                  maxlength="10" />
              </div>
            </ng-template>
            <ng-template #contextoInternacional>
              <div class="input-group">
                <span class="input-group-text">CEP</span>
                <input
                  class="form-control"
                  (blur)="save('enderecoCep'); validaCEP(enderecoNacional)"
                  formControlName="enderecoCep"
                  type="text"
                  maxlength="10" />
              </div>
            </ng-template>
          </div>
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Logradouro</span>
              <input
                (ch)="save('enderecoLogradouro')"
                class="form-control"
                formControlName="enderecoLogradouro"
                type="text"
                maxlength="80" />
            </div>
          </div>
          <div class="col-sm-2" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Nº</span>
              <input
                (blur)="save('enderecoNumero')"
                class="form-control"
                formControlName="enderecoNumero"
                type="text"
                maxlength="80" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Bairro</span>
              <input
                (blur)="save('enderecoBairro')"
                class="form-control"
                formControlName="enderecoBairro"
                type="text"
                maxlength="80" />
            </div>
          </div>
          <div class="col-sm-4" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Cidade</span>
              <input
                (blur)="save('enderecoCidade')"
                class="form-control"
                formControlName="enderecoCidade"
                type="text"
                maxlength="80" />
            </div>
          </div>
          <div class="col-sm-4" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Estado</span>
              <input
                (blur)="save('enderecoEstado')"
                class="form-control"
                formControlName="enderecoEstado"
                type="text"
                maxlength="2" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Complemento</span>
              <input
                (blur)="save('enderecoComplemento')"
                class="form-control"
                formControlName="enderecoComplemento"
                type="text"
                maxlength="80" />
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="border rounded-3 p-2">
        <legend class="w-auto">Outros</legend>
        <div class="row">
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Canhoto?</span>
              <select class="form-control" (blur)="save('canhoto')" formControlName="canhoto">
                <option value=""></option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Possui alguma deficiência?</span>
              <select
                class="form-control"
                (blur)="save('possuiDeficiencia')"
                (change)="validaDeficiencia()"
                formControlName="possuiDeficiencia">
                <option value=""></option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>
            </div>
          </div>
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group" *ngIf="form.controls.possuiDeficiencia.value === 'true'">
              <ng-select
                (blur)="save('deficiencia')"
                [multiple]="true"
                formControlName="deficiencia"
                class="custom"
                style="width: 100%">
                <ng-option *ngFor="let type of deficiencia | keyvalue" [value]="type.key">{{ type.value }}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="form.controls.possuiDeficiencia.value === 'true'">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Laudo médico</span>
              <input
                class="form-control"
                [ngClass]="inputClassDeficienciaLaudoMedico"
                type="file"
                (change)="onFileSelected($event, 'deficienciaLaudoMedico')"
                #fileUpload
                formControlName="deficienciaLaudoMedico" />
            </div>
          </div>
        </div>
        <div class="row" *ngIf="form.controls.possuiDeficiencia.value === 'true'">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Recursos especiais necessários<br />para a realização das provas</span>
              <textarea class="form-control" formControlName="deficienciaNecessidadesProva"></textarea>
            </div>
            <p class="text-danger small">
              <i class="bi bi-exclamation-circle" aria-hidden="true">&nbsp;</i> O atendimento às condições especiais
              pleiteadas ficará sujeito à análise da razoabilidade e viabilidade do solicitado.
            </p>
          </div>
        </div>
        <div class="row" *ngIf="concursoAndamento?.periodoReducao">
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Requerimento de Redução da Taxa de Inscrição</span>
              <select class="form-control" (change)="validaReducao()" formControlName="reducao">
                <option value=""></option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row" id="reducao_anexos" *ngIf="form.controls['reducao'].value === 'true'">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="alert alert-danger" style="margin-bottom: 0px">
              <p style="margin-bottom: 0px">
                Solicito a Redução da Taxa de Inscrição e atesto a veracidade das informações documentais que devo
                anexar, conforme Edital.
              </p>
            </div>
          </div>
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">
                <input type="checkbox" class="form-check-input" formControlName="reducaoTermo" />
              </span>
              <p class="form-control" style="height: 100%">
                Li e aceito os termos do requerimento de redução da taxa de inscrição
              </p>
            </div>
          </div>
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Comprovante estudante</span>
              <input
                class="form-control"
                [ngClass]="inputClassComprovanteEstudante"
                type="file"
                (change)="onFileSelected($event, 'reducaoComprovanteEstudante')"
                #fileUpload
                formControlName="reducaoComprovanteEstudante" />
            </div>
          </div>
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Comprovante renda</span>
              <input
                class="form-control"
                [ngClass]="inputClassComprovanteRenda"
                type="file"
                (change)="onFileSelected($event, 'reducaoComprovanteRenda')"
                #fileUpload
                formControlName="reducaoComprovanteRenda" />
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset class="border rounded-3 p-2" *ngIf="tipoConcurso?.localeCompare('TECADM') === 0">
        <legend class="w-auto" *ngIf="tipoConcurso?.localeCompare('TECADM') === 0">Autodeclaração</legend>
        <div class="col-sm-12" *ngIf="tipoConcurso?.localeCompare('TECADM') === 0">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <span class="input-group" style="background-color: #e9ecef; border: 1px solid #ced4da; padding: 5px 10px">
              Se autodeclara pessoa preta, parda ou indígena de acordo com a Resolução UNESP nº 86 de 25/08/2023?</span
            >
            <select
              class="form-control"
              (change)="onAutodeclaraRaca($event)"
              formControlName="autodeclaraRaca"
              required>
              <option value="" selected>Selecione</option>
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </div>
        </div>

        <div
          class="col-sm-6"
          style="margin-top: 0.5%"
          *ngIf="form.controls.autodeclaraRaca.value && tipoConcurso?.localeCompare('TECADM') === 0">
          <span class="input-group-text" style="margin-top: 0.5%"> Me autodeclaro pessoa: </span>
          <div class="input-group" style="margin-top: 0.5%">
            <select
              class="form-control"
              formControlName="autodeclaracao"
              (change)="validaAutodeclaracao($event)"
              required>
              <option value="" disabled selected>Selecione</option>
              <option value="PRETO">Preta</option>
              <option value="PARDO">Parda</option>
              <option value="INDIGENA">Indígena</option>
            </select>
          </div>
        </div>
        <div class="class" *ngIf="form.controls.autodeclaraRaca.value && tipoConcurso?.localeCompare('TECADM') === 0">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="col-sm-12">
              <span class="input-group" style="background-color: #e9ecef; border: 1px solid #ced4da; padding: 5px 10px"
                >Deseja utilizar o sistema de pontuação diferenciada nas notas finais em todas as provas escritas,
                aplicada à pessoa preta, parda ou indígena?</span
              >
              <select
                class="form-control"
                style="margin-top: 0.5%"
                formControlName="pontuacaoDiferenciada"
                required
                (change)="onPontuacaoChange($event)">
                <option value="NAO_INFORMADO" selected disabled>Selecione</option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>
            </div>
            <!-- Modal -->
            <div
              class="modal fade"
              id="dialogConcluirModal"
              tabindex="-1"
              aria-labelledby="dialogConcluirModalLabel"
              aria-hidden="true"
              data-bs-backdrop="static">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="dialogConcluirModalLabel">Atenção</h1>
                  </div>
                  <div class="modal-body">
                    Para efetivar a sua solicitação de participação na ação afirmativa como pessoa preta, parda ou
                    indígena, é preciso que seja anexada a autodeclaração assinada no campo 'Documentação complementar
                    da inscrição', conforme descrito no edital na seção 'DO SISTEMA DE PONTUAÇÃO DIFERENCIADA PARA
                    PESSOAS PRETAS, PARDAS E INDÍGENAS'
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Estou Ciente</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-sm-12"
          style="margin-top: 0.5%"
          *ngIf="form.controls.autodeclaraRaca.value && tipoConcurso?.localeCompare('TECADM') === 0">
          <div class="input-group" *ngIf="form.controls.autodeclaraRaca.value">
            <span class="input-group-text">
              <input
                type="checkbox"
                class="form-check-input"
                formControlName="declaracaoVeracidade"
                [required]="true" />
            </span>
            <p class="form-control" style="height: 100%">
              Declaro, para todos os fins, que as informações prestadas neste formulário foram por mim conferidas e são
              expressão da verdade.
            </p>
          </div>
        </div>
      </fieldset>
      <fieldset class="border rounded-3 p-2" *ngIf="concursoAndamento?.temas?.length">
        <legend class="w-auto">Pontos</legend>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="col-sm-12">
              <div class="row">
                <div class="input-group">
                  <ng-select [multiple]="true" formControlName="temas" class="custom" style="width: 100%">
                    <ng-option *ngFor="let value of concursoAndamento?.temas" [value]="value">{{ value }}</ng-option>
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="col-sm-12" style="margin-top: 0.5%">
              <p class="text-danger small">
                <i class="bi bi-exclamation-circle" aria-hidden="true">&nbsp;</i> Para a realização da prova didática, o
                candidato deverá definir, no ato da inscrição, dois dos temas publicados no Edital de Abertura de
                Inscrições.
              </p>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="border rounded-3 p-2">
        <legend class="w-auto">Identificação para acesso</legend>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Email</span>
              <input
                class="form-control"
                (keyup)="lowerCase('email')"
                (blur)="save('email')"
                type="email"
                formControlName="email"
                [email]="true"
                maxlength="50" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Senha</span>
              <input class="form-control" type="password" formControlName="senha" maxlength="64" />
            </div>
          </div>
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Confirmação</span>
              <input class="form-control" type="password" formControlName="senhaConfirmacao" maxlength="64" />
            </div>
          </div>
          <div class="col-sm-12" style="margin-top: 0.5%">
            <p class="text-danger small">
              <i class="bi bi-exclamation-circle" aria-hidden="true">&nbsp;</i> A senha deve conter, pelo menos: 1 Letra
              maiúscula, 1 Letra minúscula, 1 Número, 8 Caracteres.
            </p>
            <p class="text-danger small">
              <i class="bi bi-exclamation-circle" aria-hidden="true">&nbsp;</i> Se já possui cadastro neste site utilize
              a mesma senha.
            </p>
            <p class="text-danger small">
              <i class="bi bi-exclamation-circle" aria-hidden="true">&nbsp;</i> Se não lembra sua senha
              <a routerLink="/sistema/senha-recuperar">clique aqui</a> para recuperar.
            </p>
          </div>
        </div>
      </fieldset>
      <div class="row" style="margin-top: 20px" *ngIf="conferencia === false">
        <div class="col-6">
          <a (click)="voltar()" class="btn btn-secondary btn-sm"
            ><i class="bi bi-arrow-left-short" aria-hidden="true">&nbsp;</i> <span>Voltar</span></a
          >
        </div>
        <div class="col-6 d-flex flex-row-reverse">
          <button (click)="conferir()" class="btn btn-primary btn-sm">
            <i class="bi bi-check-circle" aria-hidden="true">&nbsp;</i> Enviar
          </button>
        </div>
      </div>
      <div class="row" style="margin-top: 20px" *ngIf="conferencia === true">
        <div class="col-6">
          <a (click)="cancelar()" class="btn btn-secondary btn-sm"
            ><i class="bi bi-pencil" aria-hidden="true">&nbsp;</i> <span>Alterar</span></a
          >
        </div>
        <div class="col-6 d-flex flex-row-reverse">
          <button (click)="gravar()" class="btn btn-success btn-sm">
            <i class="bi bi-check-circle" aria-hidden="true">&nbsp;</i> Confirmar
          </button>
        </div>
      </div>
    </form>
  </div>
</section>
